import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { AlertService } from '../../core/alert.service';
import { ProductClassification } from './product-classification.models';
import { RequestService } from '../../core/request.service';
import { Constants } from '../../app.constants';
import { LookupSource, LS } from '../base/base.models';

@Injectable({
  providedIn: 'root'
})
export class ProductClassificationService extends BaseService {

  constructor(protected requestService: RequestService, private alertService: AlertService) {
    super(requestService);
  }

  public getParentsOptions(url: string) {
    return this.getOptionsByMultipleFieldsFilter(url, 'code', ['code', 'name'], (result: any) => {
      if (Array.isArray(result)) {
        for (let p of result) {
          p.name = `${p.code} ${p.name}`;
        }
      } else {
        result.name = `${result.code} ${result.name}`;
      }
      return result;
    });
  }

  public createClassification(url: string, classification: ProductClassification, onSuccess: Function, onFail?: Function) {
    this.create(url, classification, (response) => {
      onSuccess(response);
    }, (error) => {
      onFail ? onFail(error) : this.alertService.error(error, true);
    })
  }

  public updateClassification(url: string, classification: ProductClassification, onSuccess: Function, onFail?: Function) {
    this.update(url, classification, (response) => {
      onSuccess(response);
    }, (error) => {
      onFail ? onFail(error) : this.alertService.error(error, true);
    })
  }

  public getAttributesByType(type: string, includeCode: boolean = true) {
    let url: string;

    switch (type) {
      case 'ASSORTMENT': url = `${Constants.contextPath}/product-assortments`; break;
      case 'BRAND': url = `${Constants.contextPath}/product-brands`; break;
      case 'CATEGORY': url = `${Constants.contextPath}/product-categories`; break;
      case 'GROUP': url = `${Constants.contextPath}/product-groups`; break;
      case 'MERCHANDISE_GROUP': url = `${Constants.contextPath}/product-merchandise-groups`; break;
    }

    return this.getOptionsByMultipleFieldsFilter(url, 'name', ['name', 'code'], (response) => {
      if (Array.isArray(response)) {
        if (includeCode)
          for (let a of response)
            a.name = `${a.code}: ${a.name}`;
      }
      else {
        if (includeCode)
          response.name = `${response.code}: ${response.name}`;
      }
      return response;
    });
  }

  public getProductMerchandiseLookup() {
    return this.getLookup(LS.get('NCI'), LS.get('NC'), LookupSource.PRODUCT_MERCHANDISE, `parent is null`);
  }
}
