import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { HttpRequestType, RequestService } from '../../../core/request.service';
import { Constants, SearchOperations } from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends BaseService {

  configurationUrl = '/country-configurations';

  constructor(protected requestService: RequestService) {
    super(requestService);
  }

  public getCountriesOptions() {
    return this.getOptions(`${Constants.contextPath}/countries`);
  }

  public getCountryConfiguration(countryId: string, onSuccess: Function) {
    this.getAll(`${Constants.contextPath}/country-configurations/list?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}country${SearchOperations.SPLIT_JOIN_OPERATOR}id${SearchOperations.EQUALITY}${countryId}${SearchOperations.RIGHT_PARENTHESES_READABLE}`, (res) => {
      onSuccess(res);
    })
  }

  public getCountryConfigurationsByCountryId(uuid: string, onSuccess: Function) {
    let field = "country__id";
    let url = `${Constants.contextPath}${this.configurationUrl}?search=${SearchOperations.LEFT_PARENTHESES_READABLE}${field}${SearchOperations.EQUALITY}${uuid}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;

    return this.requestService.createRequestAsPromise(HttpRequestType.Get, url, null, null, (response) => {
      onSuccess(response.content);
    })
  }

  public createCountryConfiguration(formData: any, onSuccess: Function){
    this.create(`${Constants.contextPath}${this.configurationUrl}`, formData, (response)=> {
      onSuccess(response);
    })
  }

  public updateCountryConfiguration(formData: any, onSuccess: Function){
    this.update(`${Constants.contextPath}${this.configurationUrl}`, formData, (response) => {
      onSuccess(response);
    })
  }

  public deleteCountryConfiguration(uuid: string, onSuccess: Function){
    this.delete(`${Constants.contextPath}${this.configurationUrl}`, uuid, (response) => {
      onSuccess(response)
    })
  }
}
