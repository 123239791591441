import { BaseModelActive } from '../../../shared/base/base.models';
import { PointOfSale } from '../../../inside-layout/routes/points-of-sale/points-of-sale.models';

export class MeanOfDelivery extends BaseModelActive {
    description?: string;
    pointOfSale?: PointOfSale | string;
    type?: Type | string;
    validFrom?: string;
    validTo?: string;
}

export enum Type { 
    PERSONAL_PICKUP, SELLER_DISTRIBUTION, EXPRESS_DELIVERY
}
