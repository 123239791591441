import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { BusinessPartnerStatus } from './business-partners.models';
import { HttpRequestType } from 'src/app/core/request.service';
import { Constants, SearchOperations } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class BusinessPartnersService extends BaseService {

  urlDeliveryLocation: string = '/delivery-locations';

  getBusinessPartnerOptions() {
    return this.getOptions(`${Constants.contextPath}/partners`, 'name');
  }

  public changeBusinessPartnerStatus(url: string, status: BusinessPartnerStatus, id: string, onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${url}/${id}/change-status/${status}`, null, null, (response) => onSuccess(response));
  }

  public getBusinessPartnerLookup(){
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/partners`, 'name', ['name'], (response) => {      
      return response;
    })
  }

  getDeliveryLocationsOptions() {
    return this.getOptions(`${Constants.contextPath}${this.urlDeliveryLocation}`, 'name');
  }

  getAllDeliveryLocationsByBusinessPartner(id:string,filter: string , onSuccess:Function) {
    this.getAll(`${Constants.contextPath}${this.urlDeliveryLocation}/list?${filter}`, (res) => {
      onSuccess(res);
    })
  }

  createDeliveryLocation(formData: any, onSuccess: Function){
    this.create(`${Constants.contextPath}${this.urlDeliveryLocation}`, formData, (res) => {
      onSuccess(res);
    });
  }

  updateDeliveryLocation(formData: any, onSuccess: Function){
    this.update(`${Constants.contextPath}${this.urlDeliveryLocation}`, formData, (res) => {
      onSuccess(res);
    });
  }

  deleteDeliveryLocation(id:string, onSuccess: Function){
    this.delete(`${Constants.contextPath}${this.urlDeliveryLocation}`, id, (res) => {
      onSuccess(res);
    });
  }

  activateDeliveryLocation(id: string, onSuccess: Function){
    this.patch(`${Constants.contextPath}${this.urlDeliveryLocation}/${id}/activate`, null, (res) => {
      onSuccess(res);
    });
  }

  deactivateDeliveryLocation(id: string, onSuccess: Function){
    this.patch(`${Constants.contextPath}${this.urlDeliveryLocation}/${id}/deactivate`, null,(res) => {
      onSuccess(res);
    });
  }

  suspendDeliveryLocation(id: string, onSuccess: Function){
    this.patch(`${Constants.contextPath}${this.urlDeliveryLocation}/${id}/suspend`, null,(res) => {
      onSuccess(res);
    });
  }
}
