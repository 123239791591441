import { BaseModelActive, BaseModel, DayOfWeek } from '../../../shared/base/base.models';

export class PointOfSale extends BaseModelActive {
    
    code?: string;
    name?: string;
    type?: PointOfSaleType | string;
    priceType?: PointOfSaleType | string;
    geoX?: number;
    geoY?: number;
    workingHoursFrom?: string;
    workingHoursTo?: string;
    quantityControl?: boolean = false;
    workingDays?: PointOfSaleWorkingDay[] | string = [];
}

export enum PointOfSaleType { 
    WHOLESALE, RETAIL, WEB
}

export enum PriceType {
    VAT_INCLUDED, VAT_EXCLUDED
}

export class PointOfSaleWorkingDay extends BaseModel {
    day: DayOfWeek | string;
}