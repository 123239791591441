import { BaseModelActive } from '../../../shared/base/base.models';
import { PointOfSale } from '../points-of-sale/points-of-sale.models';
import { PromotionalCodeDocument } from '../promotional-code-documents/promotional-code-documents.models';
import { Product } from '../products/products.models';
import { PaymentMethod } from '../payment-methods/payment-methods-models';
import { GiftPackage } from '../gift-package/gift-package.models';
import { Type } from '../means-of-delivery/means-of-delivery.models';

export class RebatePolicy extends BaseModelActive {
    documentNumber?: string;
    validFrom?: Date;
    validTo?: Date;
    businessPartner?: BusinessPartner;
    pointOfSale?: string | PointOfSale;
    type?: RebatePolicyType | string;
    option?: RebatePolicyOption | string;
    userType?: PolicyUserType | string;
    minimalOrderValue?: number;
    orderSequentialNumber?: number;
    referencePeriodFrom?: Date;
    referencePeriodTo?: Date;
    paymentMethod?: string | PaymentMethod;
    promotionalCodeDocument?: string | PromotionalCodeDocument;
    ordersEligible?: number;
    ordersApplied?: number;
    defaultOn?: boolean = true;
    products?: string[] | Product[] = [];
    giftPackages?: string[] | GiftPackage[] = [];
    meansOfDeliveryType: string | Type;
}

export class BusinessPartner extends BaseModelActive {
    name?: string;
}

export enum RebatePolicyType {
    PERCENT, PROMO_CODE, GIFT
}

export enum RebatePolicyOption {
    ORDER_VALUE, TOTAL_ORDERS_PERIOD, SEQUENTIAL_ORDER, PROMO_CODE, PAYMENT_METHOD, MEANS_OF_DELIVERY
}

export enum PolicyUserType {
    ALL, PUBLIC_MEMBER, REGISTERED_MEMBER, VIP_MEMBER, BUSINESS_PARTNER, BUSINESS_PARTNER_TYPE_I, BUSINESS_PARTNER_TYPE_II, BUSINESS_PARTNER_TYPE_III, BUSINESS_PARTNER_TYPE_IV, BUSINESS_PARTNER_TYPE_V
}