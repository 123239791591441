import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants, SearchOperations } from '../../../app.constants';
import { ProductCatalogue, ProductAttributeType, AttributeSelection, ProductCatalogueHelper } from './product-catalogues.models';
import { RequestService, HttpRequestType } from 'src/app/core/request.service';
import { AlertService } from 'src/app/core/alert.service';
import { filter } from 'rxjs/operators';
import { Product } from '../products/products.models';

@Injectable({
  providedIn: 'root'
})
export class ProductCataloguesService extends BaseService {
  private url: string = `${Constants.contextPath}/product-catalogues`;

  constructor(protected requestService: RequestService, private alertService: AlertService) {
    super(requestService);
  }

  public copyProductCatalogue(id: string, code: string, copyType: string, selectedItems: string[], onSuccess: Function, onFail: Function = null) {
    let body = {
      catalogue: id,
      code: code,
      copyType: copyType,
      items: selectedItems
    }
    this.requestService.createRequest(HttpRequestType.Post, `${this.url}/copy-catalogue`, body, null, (response: ProductCatalogue) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else
        this.alertService.error(error, true);
    });
  }

  public changeStatus(id: string, activate: boolean, onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Patch, `${this.url}/${id}/${activate ? 'activate' : 'deactivate'}`, null, null, (response: ProductCatalogue) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else
        this.alertService.error(error, true);
    });
  }

  public addByAttribute(id: string, selection: AttributeSelection, intialFilter?: string, onSuccess?: Function, onFail: Function = null) {
    let filter = '';
    if(intialFilter!=null && intialFilter!='')
      filter = `?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${intialFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;

    this.requestService.createRequest(HttpRequestType.Put, `${this.url}/${id}/add-by-attribute${filter}`, selection, null, (response) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else {
        this.alertService.error(error, true);
      }
    });
  }

  public addAll(id: string, selection: AttributeSelection, onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Put, `${this.url}/${id}/add-all`, selection, null, (response) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else {
        this.alertService.error(error, true);
      }
    });
  }

  public getProductCataloguesByProduct(productCode: string, onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Patch, `${this.url}/get-where-product/${productCode}`, null, null, (response) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else {
        this.alertService.error(error, true);
      }
    });
  }

  public getProductCataloguesByOfferedProduct(offeredProductId: string | number, onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Patch, `${this.url}/get-where-offered-product/${offeredProductId}`, null, null, (response) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else {
        this.alertService.error(error, true);
      }
    });
  }

  public deactivateExpiredCatalogues(onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Patch, `${this.url}/deactivate-expired`, null, null, (response: ProductCatalogue) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else
        this.alertService.error(error, true);
    });
  }

  public deactivateSingleCatalogueItems(body: string | string[], onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Patch, `${this.url}/deactivate-items/single`, body, null, (response: ProductCatalogue) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else
        this.alertService.error(error, true);
    });
  }

  public deactivateItems(body:string | ProductCatalogueHelper[], onSuccess?: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/product-catalogues/deactivate-items`, body, null, 
    (response) => {
      onSuccess(response);
    }, (error) => {
        this.alertService.error(error, true);
      }
    )
  }

  public copyCatalogueWithDeactivatedItems(catalogue: string | number, body: string[], code: string, onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Patch, `${this.url}/copy-catalogue-with-deactivated-items/${code}/${catalogue}`, body, null, (response: ProductCatalogue) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else
        this.alertService.error(error, true);
    });
  }

  public sendEmailToPartner(id: string, onSuccess: Function, onFail: Function = null) {
    this.requestService.createRequest(HttpRequestType.Post, `${this.url}/email-to-partner/${id}`, null, null, (response: ProductCatalogue) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else
        this.alertService.error(error, true);
    });
  }


}
