import { BaseModel } from '../../../shared/base/base.models';

export class Template extends BaseModel {
    name?: string;
    templateAttributes?: TemplateAttribute[] = [];
}

export class TemplateAttribute extends BaseModel {
    template?: Template;
    name?: string;
    dataType?: TemplateAttributeDataType;
    searchable?: boolean;
}

export enum TemplateAttributeDataType {
    INTEGER, DECIMAL, DATE, VARCHAR
}