import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants } from '../../../app.constants';
import { LookupSource, LS } from 'src/app/shared/base/base.models';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService extends BaseService {

  public getPaymentMethodsOptions() {
    return this.getOptions(`${Constants.contextPath}/payment-methods`);
  }
  
  public getPaymentMethodsLookup(){
    return this.getLookup(LS.get('CT'), LS.get('C'), LookupSource.PAYMENT_METHOD);
  }
}
