import { BaseModel, Image } from "../../../shared/base/base.models";

export class PaymentMethod extends BaseModel {
    code?: string;
    type?: PaymentType | string;
    description?: string;
    image?: Image;
}

export enum PaymentType {
    CARD, CASH , ON_LINE, ON_LINE_OPA, GIRO
}

export enum PaymentMethodStatus {
    ACTIVE, INACTIVE
}