import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/base/base.service';
import { Constants, SearchOperations } from 'src/app/app.constants';
import CustomStore from "devextreme/data/custom_store";
import { AttributeSelection, ProductCatalogueHelper } from '../product-catalogues/product-catalogues.models';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { AlertService } from 'src/app/core/alert.service';

@Injectable({
  providedIn: 'root'
})
export class OfferedProductsService extends BaseService {
  
  constructor(protected requestService: RequestService, private alertService: AlertService) {
    super(requestService);
  }

  public getOfferedProductsOptions() {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/offered-products`, `product${SearchOperations.SPLIT_JOIN_OPERATOR}name`, [`product${SearchOperations.SPLIT_JOIN_OPERATOR}name`, `product${SearchOperations.SPLIT_JOIN_OPERATOR}code`], (response) => {
      if(Array.isArray(response))
        for(let i of response)
          i.lookup = i.product.code + ': ' + i.product.name;
      else
        response.lookup = response.product.code + ': ' + response.product.name;
        
      return response
    });
  }

  public getOfferedProductsOptionsByPointOfSale(pointOfSaleId: string, intialFilter?: string) {
    let url: string = `${Constants.contextPath}/offered-products`
    let sortBy: string = `product${SearchOperations.SPLIT_JOIN_OPERATOR}name`
    let pointOfSaleEquality = `pointOfSale${SearchOperations.SPLIT_JOIN_OPERATOR}id${SearchOperations.EQUALITY}${pointOfSaleId}`
    return {
      paginate: true,
      pageSize: this.pageSize,
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {
          let filter = '';

          if(intialFilter)
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${pointOfSaleEquality}${SearchOperations.SPLIT_SINGLE_OPERATION}${intialFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;
          else
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;

          if (loadOptions.searchExpr && loadOptions.searchValue) {
            let nameFilter = `product${SearchOperations.SPLIT_JOIN_OPERATOR}name${SearchOperations.LIKE}${loadOptions.searchValue}`;
            let codeFilter = `product${SearchOperations.SPLIT_JOIN_OPERATOR}code${SearchOperations.LIKE}${loadOptions.searchValue}`;

          if(intialFilter)
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${nameFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${intialFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}${SearchOperations.KEY_OR}${SearchOperations.LEFT_PARENTHESES_READABLE}${codeFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${intialFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;
          else
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${nameFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}${SearchOperations.KEY_OR}${SearchOperations.LEFT_PARENTHESES_READABLE}${codeFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;

          }
          return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
            let data = response;
            if(response.content != null)
              data = response.content;

            //filter
            for(let i of data)
              i.lookup = i.product.code + ': ' + i.product.name;

            return data;
          });
        },
        byKey: (id: number | string | any) => {

          if (id != null)
            if(typeof id === 'string' || typeof id === 'number')
              return this.getByIdAsPromise(url, id, (response: any) => {
                response.lookup = response.product.code + ': ' + response.product.name;
                return response;
              });
            else if(typeof id === 'object')
              return this.getByIdAsPromise(url, id.id, (response: any) => {
                response.lookup = response.product.code + ': ' + response.product.name;
                return response;
              });
        }
      })
    };
  }

  public getOfferedProductsByPointOfSaleOptions(pointOfSaleId: string) {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/offered-products/shop/${pointOfSaleId}`, `product${SearchOperations.SPLIT_JOIN_OPERATOR}name`, [`product${SearchOperations.SPLIT_JOIN_OPERATOR}name`, `product${SearchOperations.SPLIT_JOIN_OPERATOR}code`], (response) => {
      if(Array.isArray(response))
        for(let i of response)
          i.lookup = i.product.code + ': ' + i.product.name;
      else
        response.lookup = response.product.code + ': ' + response.product.name;
        
      return response
    });
  }

  public getOfferedProductsByAttributeAndPointOfSale(pointOfSaleId: string, onSuccess: Function) {
    this.getAll(`${Constants.contextPath}/offered-products/get-by-attribute/${pointOfSaleId}`, (response) => {
      onSuccess(response);
    })
  }

  public getByAttribute(id: string, selection: AttributeSelection, onSuccess?: Function, onFail?: Function) {
    this.requestService.createRequest(HttpRequestType.Put, `${Constants.contextPath}/offered-products/get-by-attribute/${id}`, selection, null, (response) => {
      onSuccess(response);
    }, (error) => {
      if(onFail != null)
        onFail(error);
      else {
        this.alertService.error(error, true);
      }
    });
  }

  public getAllOfferedProductsByPointOfSale(pointOfSaleId: string, onSuccess?: Function) {
    this.requestService.createRequest(HttpRequestType.Get, `${Constants.contextPath}/offered-products/get-active-for-point-of-sale/${pointOfSaleId}`, null, null, (response) => {
      onSuccess(response);
    });
  }

  public getAvailableQuantityForProductsByPOS(pointOfSaleId: string, offeredProducts: string[], onSuccess?: Function) {
    let pointOfSaleFilter = `pointOfSale${SearchOperations.SPLIT_JOIN_OPERATOR}id${SearchOperations.EQUALITY}${pointOfSaleId}`;
    let finalFilterWithOr = '';

    for(let i = 0; i < offeredProducts.length; i++)
      finalFilterWithOr+= `${SearchOperations.LEFT_PARENTHESES_READABLE}${pointOfSaleFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}id${SearchOperations.EQUALITY}${offeredProducts[i]}${SearchOperations.RIGHT_PARENTHESES_READABLE}${i == offeredProducts.length-1 ? "" : SearchOperations.KEY_OR}`;

    let filter = `?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${finalFilterWithOr}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
    this.getAll(`${Constants.contextPath}/offered-products/quantities${filter}`, (response) => {
      onSuccess(response);
    })
  }
}