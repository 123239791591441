import { BaseModelActive, Image } from "../../../shared/base/base.models";

export class Banner extends BaseModelActive {
    title?: string;
    subtitle?: string;
    description?: string;
    buttonText?: string;
    url?: string;
    type?: BannerType | string;
    image?: Image;
    icon?: string;
    dateFrom?: Date;
    dateTo?: Date;
    scope?: BannerScope | string;
    scopeId?: string;

    // helpers
    scopeUser?: string;
    scopeUserType?: string;
}

export enum BannerType { 
    HOME_CAROUSEL, HOME_FIXED, POPUP
}

export enum BannerScope {
    USER_TYPE, SINGLE_USER
}