import { BaseModel, Image } from "../../../shared/base/base.models";
import { Currency } from "../currencies/currencies.models";

export class Country extends BaseModel {
    name?: string;
    officialName?: string;
    alpha2Code?: string;
    alpha3Code?: string;
    numericCode?: string;
    topLevelDomain?: string;
    currency?: string | Currency;
}

export class CountryConfiguration {
    type: string | CountryConfigurationKey;
    value: string;
    version?: number;
    id?: string;
}

export enum CountryConfigurationKey {
    PHONE_PREFIX, FLAG_URL, ID_NUMBER_LENGTH, VAT_NUMBER_LENGTH
}

export class CountryConfigurationIn {
    id?: string;
    type?: CountryConfigurationKey;
    value?: string;
    country?: string;
    version?: number;
}