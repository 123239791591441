import { BaseModelActive, BaseModel } from '../../../shared/base/base.models';
import { PointOfSale } from '../points-of-sale/points-of-sale.models';
import { MeanOfDelivery } from '../means-of-delivery/means-of-delivery.models';
import { DeliveryZone } from '../delivery-zones/delivery-zones.models';

export class DistributionCalendar extends BaseModelActive {
    pointOfSale?: PointOfSale | string;
    meansOfDelivery?: MeanOfDelivery | string;
    deliveryZone?: DeliveryZone | string;
    address?: string;
    date?: Date;
    timeFrom?: Date;
    timeTo?: Date;
    capacity?: number;
    usedCapacity?: number;
}

export class DistributionCalendarBlueprint extends BaseModel {
    pointOfSale?: PointOfSale | string;
    meansOfDelivery?: MeanOfDelivery | string;
    deliveryZone?: DeliveryZone | string;
    dateFrom?: Date;
    dateTo?: Date;
}