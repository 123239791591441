import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants, SearchOperations } from '../../../app.constants';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class CostPoliciesService extends BaseService {

  public getCostPoliciesOptions() {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/cost-policies`, 'value', ['documentNumber', 'description'], (response) => {
      if(Array.isArray(response))
        for(let i of response)
          i.lookup = i.documentNumber + ': ' + i.description;
      else
        response.lookup = response.documentNumber + ': ' + response.description;
        
      return response
    } );
  }
  
  public getCostPoliciesOptionsByPosId(pointOfSaleId: string) {
    let url: string = `${Constants.contextPath}/cost-policies`
    let sortBy: string = 'value'
    let pointOfSaleEquality = `pointOfSale${SearchOperations.SPLIT_JOIN_OPERATOR}id${SearchOperations.EQUALITY}${pointOfSaleId}`
    return {
      paginate: true,
      pageSize: this.pageSize,
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {
          let filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;
          if (loadOptions.searchExpr && loadOptions.searchValue) {
            let nameFilter = `description${SearchOperations.LIKE}${loadOptions.searchValue}}`;
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${nameFilter}${SearchOperations.SPLIT_SINGLE_OPERATION}${pointOfSaleEquality}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;

          }
          return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
            let data = response;
            if(response.content != null)
              data = response.content;

            // for(let i of data)
            //   i.lookup = i.product.code + ': ' + i.product.name;

            return data;
          });
        },
        byKey: (id: number | string | any) => {

          if (id != null)
            if(typeof id === 'string' || typeof id === 'number')
              return this.getByIdAsPromise(url, id, (response: any) => {
                // response.lookup = response.product.code + ': ' + response.product.name;
                return response;
              });
            else if(typeof id === 'object')
              return this.getByIdAsPromise(url, id.id, (response: any) => {
                // response.lookup = response.product.code + ': ' + response.product.name;
                return response;
              });
        }
      })
    };
  }

  public getTaxRateOptions() {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/tax-rates`, 'code', ['code', 'percentage'], (response) => {
      if (Array.isArray(response))
        for (let p of response)
          p.label = `${p.code} (${p.percentage})`;
      else
        response.label = `${response.code} (${response.percentage})`;
      return response;
    });
  }
}
