import { BaseModel } from "../../../shared/base/base.models";

export class News extends BaseModel {
    title?: string = '';
    link?: string = '';
    newsParagraphs?: NewsParagraph[] = new Array<NewsParagraph>();
    icon: string = '';
}

export class NewsParagraph extends BaseModel {
    paragraph?: string;
    imagePath?: string;
    priority?: number;
}
