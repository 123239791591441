import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants } from '../../../app.constants';
import { LookupSource, LS } from 'src/app/shared/base/base.models';

@Injectable({
  providedIn: 'root'
})
export class MunicipalitiesService extends BaseService {
  public getMunicipalitiesOptions() {
    return this.getOptions(`${Constants.contextPath}/municipalities`);
  }

  public getMunicipalitiesLookup(){
    return this.getLookup(LS.get('N'), LS.get('N'), LookupSource.MUNICIPALITY);
  }

  public getMunicipalitiesList(filter: string, sort: string, onSuccess: Function){
    this.getList(`${Constants.contextPath}/municipalities`, filter, sort, (response) => {
      onSuccess(response);
    });
  }
}
