import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants, SearchOperations } from '../../../app.constants';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { AlertService } from 'src/app/core/alert.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxiTotalItemComponent } from 'devextreme-angular/ui/nested';
import { LookupSource, LS } from 'src/app/shared/base/base.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  protected totalCount: number;

  constructor(protected requestService: RequestService, private alertService: AlertService, private authService: AuthService, private http: HttpClient) {
    super(requestService);
  }

  public getUsersOptions() {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/users`, 'name', ['name', 'login', 'email'], (response) => {
      if (Array.isArray(response))
        for (let u of response)
          u.name = `${u.name}, ${u.email}`;
      else
        response.name = `${response.name}, ${response.email}`;

      return response;
    })
  }

  public changeStatus(id: string, active: boolean, onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/${id}/${active ? 'activate' : 'deactivate'}`, null, null, (response) => {
      onSuccess(response);
    }, (error) => {
      this.alertService.error(error, true);
    });
  }

  public resetFailedLoginAttempts(id: string | number, onSuccess: Function) {
    let url = `${Constants.contextPath}/users/${id}/reset-access-log`;
    this.requestService.createRequest(HttpRequestType.Patch, url, null, null, (response) => {
      onSuccess(response);
    }, (error) => {
      this.alertService.error(error, true);
    });
  }

  public getUsersByRole(role: string) {
    let url = `${Constants.contextPath}/users`;
    let sortBy = `user${SearchOperations.SPLIT_JOIN_OPERATOR}dateCreated`;
    let fields = [`user${SearchOperations.SPLIT_JOIN_OPERATOR}name`, `user${SearchOperations.SPLIT_JOIN_OPERATOR}email`];
    return {
      paginate: true,
      pageSize: this.pageSize,
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {
          let filter = '';
          if (sortBy != null)
            filter = `&sortBy=${sortBy}&sortOrder=asc`;
          if (loadOptions.searchExpr && loadOptions.searchValue) {
            let fieldsFilter = '';

            if (fields != null && fields.length > 0) {
              let counter = 0;
              for (let field of fields) {
                let OR = `${SearchOperations.KEY_OR}`;
                if (counter == 0)
                  OR = '';
                fieldsFilter = fieldsFilter + `${OR}${SearchOperations.LEFT_PARENTHESES_READABLE}${field}${SearchOperations.LIKE}${loadOptions.searchValue}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
                counter++;
              }
            }
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${fieldsFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;
          }

          return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
          });
        },
        byKey: (id: any) => {
          return id;
        }
      })
    };
  }

  public exportToCSV(onSuccess: Function) {
    const httpHeaders = new HttpHeaders({
      Authorization: this.authService.authHeaders.get('Authorization'),
      Accept: 'text/plain, */*, application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    // Requested by AMKO to export users list to xls
    this.http.post(`${Constants.contextPath}/users/report?reportType=XLS`, null, { responseType: 'blob', headers: httpHeaders }).subscribe((res: any) => onSuccess(res));
  }
}
